<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <v-avatar
            tile
            width="150"
            height="auto"
            class="mb-4 mx-auto"
          >
            <img
              src="@/assets/images/logo.png"
              alt="Servercentralen reset password"
            >
          </v-avatar>

          <h5>{{ $t("userAuth.resetPassword") }}</h5>

          <v-alert
            v-if="isResetSuccess"
            border="top"
            color="purple"
            type="success"
            class="mt-4"
          >
            {{ $t("userAuth.restPasswordSuccessComment") }}<br>
            {{ $t("userAuth.tryLoginAgainWithNewPassword") }}
          </v-alert>

          <template v-else>
            <v-form
              ref="form"
              v-model="validForm"
              class="mt-5"
              lazy-validation
            >
              <v-text-field
                v-model="userPassword"
                :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isShowPassword ? 'text' : 'password'"
                :label="`${$t('common.password')}*`"
                :rules="passwordRules"
                @click:append="isShowPassword = !isShowPassword"
              />

              <v-text-field
                v-model="userConfirmPassword"
                :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isShowPassword ? 'text' : 'password'"
                class="mt-4"
                :label="`${$t('common.confirmPassword')}*`"
                :rules="confirmPasswordRules"
                @click:append="isShowPassword = !isShowPassword"
              />

              <v-alert
                v-if="errorMessage"
                border="right"
                colored-border
                type="error"
                elevation="2"
                class="mt-2 text-left"
                dismissible
              >
                {{ errorMessage }}
              </v-alert>

              <v-btn
                block
                color="primary"
                class="mt-4"
                :disabled="!validForm"
                :loading="isLoading"
                @click="submitForm"
              >
                {{ $t("userAuth.setPassword") }}
              </v-btn>
            </v-form>
          </template>

          <div class="d-flex justify-space-between mt-4">
            <v-btn
              block
              color="success"
              class="font-weight-bold text-subtitle-2"
              to="/signin"
            >
              {{ $t("userAuth.signIn") }}
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'

  export default {
    name: 'ResetPassword',

    metaInfo: {
      title: 'Reset Password',
    },

    data () {
      return {
        validForm: true,
        isLoading: false,
        isShowPassword: false,
        isResetSuccess: false,
        errorMessage: '',
        userPassword: '',
        userConfirmPassword: '',
        token: '',
        passwordRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.password') }),
          (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || this.$t('error.invalidPassword'),
        ],
        confirmPasswordRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.confirmPassword') }),
          (v) => v === this.userPassword || this.$t('error.confirmPasswordNotMatch'),
        ],
      }
    },

    mounted() {
      this.token = this.$route.params.token || ''
      if (!this.token) {
        this.$router.push('/signin')
      }
    },

    methods: {
      ...mapActions({
        resetPassword: 'resetPassword',
      }),

      async submitForm () {
        this.errorMessage = ''

        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.isLoading = true
        try {
          await this.resetPassword({
            password: this.userPassword,
            token: this.token,
          })

          this.isResetSuccess = true
        } catch (error) {
          this.errorMessage = util.getErrorResponse(error)
        }
        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
